






import { Component } from 'vue-property-decorator'

import { isRelated } from '../../../../services'
import { log } from '../../../../support'
import { ResolvedBlogPost } from '../../../../models'

import { AbstractModuleUi } from '../../_abstract'

import { BlogFeedModule } from '../BlogFeed.contracts'

/**
 * Container component for the `BlogFeedModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<BlogFeedModuleUi>({
  name: 'BlogFeedModuleUi',
  components: {
    BlogFeedModuleUiPresentation: () => import(
      /* webpackChunkName: "modules/BlogFeed" */
      './BlogFeed.ui.presentation.vue'
    )
  }
})
export class BlogFeedModuleUi extends AbstractModuleUi<BlogFeedModule> {
  /**
   * Posts from `this.module.content` with all related data already resolved and ready to use.
   */
  public posts: ResolvedBlogPost[] = []

  /**
   * @inheritDoc
   */
  public async fetchRelated (): Promise<void> {
    await this.fetchPosts()
  }

  /**
   * Determines whether the component should be rendered.
   */
  public get shouldRender (): boolean {
    return this.hasContent && this.hasPosts
  }

  /**
   * Fetches the blog posts from the `RelatedService`.
   */
  private async fetchPosts (): Promise<void> {
    // Assign the object to the helper const for easier access.
    const unresolvedPosts = this.data?.content?.posts

    if (!isRelated(unresolvedPosts)) {
      const message: string = 'BlogFeedModuleUi.fetchPosts(): [this.data.content.posts] is not a valid related object!'
      log([message, unresolvedPosts], 'error')
      return
    }

    try {
      this.posts = await this.relatedService.resolve<ResolvedBlogPost[]>(unresolvedPosts)
    } catch (error) {
      const message: string = 'BlogFeedModuleUi.fetchPosts(): Failed to fetch the related data!'
      log([message, error], 'error')
    }
  }

  /**
   * Determines whether the component has successfully fetched the blog posts
   * from the `RelatedService`.
   */
  private get hasPosts (): boolean {
    return this.posts.length > 0
  }
}

export default BlogFeedModuleUi
