



















import { Component, Prop } from 'vue-property-decorator'

import { ResolvedBlogPost } from '../../../../models'

import { AbstractModuleUiPresentation } from '../../_abstract'

/**
 * Presentational component for the `BlogFeedModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<BlogFeedModuleUiPresentation>({
  name: 'BlogFeedModuleUiPresentation',
  components: {
    Card: () => import(
      /* webpackChunkName: "molecules/Card" */
      '../../../molecules/Card/Card.vue'
    )
  }
})
export class BlogFeedModuleUiPresentation extends AbstractModuleUiPresentation {
  /**
   * Array of posts to render.
   */
  @Prop({ type: Array, required: true })
  public readonly posts!: ResolvedBlogPost[]

  /**
   * Determines whether the component has been provided with the correct `posts` prop.
   */
  public get hasPosts (): boolean {
    return typeof this.posts !== 'undefined' &&
      Array.isArray(this.posts) &&
      this.posts.length > 0
  }
}

export default BlogFeedModuleUiPresentation
